import * as React from "react";
import Layout from "../components/layout";
import { ContactIconsList } from '../components/social/contactIcons';
import {
  createStyles,
  Text,
  Title,
  SimpleGrid,
  TextInput,
  Textarea,
  Button,
  Group,
  ActionIcon,
} from '@mantine/core';
import { IconBrandInstagram, IconBrandFacebook, IconBrandWhatsapp } from '@tabler/icons';
const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: 400,
    boxSizing: 'border-box',
    backgroundImage: `linear-gradient(45deg, #3f51b5 0%, rgb(44, 55, 120) 100%)`,
    borderRadius: 0,
    padding: theme.spacing.xl * 2.5,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      padding: theme.spacing.xl * 1.5,
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    color: theme.white,
    lineHeight: 1,
    fontSize: '44px'
  },

  description: {
    color: theme.colors[theme.primaryColor][0],
    maxWidth: 300,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: '100%',
    },
  },

  form: {
    backgroundColor: theme.white,
    padding: theme.spacing.xl,
    borderRadius: theme.radius.md,
    boxShadow: theme.shadows.lg,
  },

  social: {
    color: theme.white,

    '&:hover': {
      color: theme.colors[theme.primaryColor][1],
    },
  },

  input: {
    backgroundColor: theme.white,
    borderColor: theme.colors.gray[4],
    color: theme.black,

    '&::placeholder': {
      color: theme.colors.gray[5],
    },
  },

  inputLabel: {
    color: theme.black,
  },

  control: {
    backgroundColor: theme.colors[theme.primaryColor][6],
  },
}));

const social = [{
  icon: IconBrandFacebook,
  url: 'https://www.facebook.com/Affordable-Flooring-of-SWFL-593198734454215/'
},{
  icon: IconBrandInstagram,
  url: 'https://www.instagram.com/affordableflooringswfl/?hl=en'
},{

  icon: IconBrandWhatsapp,
  url: 'https://www.angi.com/companylist/us/fl/bonita-springs/affordable-flooring-specialists-llc-reviews-9089714.html'
}
];


const Contact = () => {
  const { classes } = useStyles();

  const icons = social.map((Icon, index) => (
    <ActionIcon component="a" key={index} size={28} className={classes.social} variant="transparent" href={Icon.url}>
      <Icon.icon size={22} stroke={1.5} />
    </ActionIcon>
  ));
  return (
    <Layout>

    <div className={classes.wrapper}>
      <SimpleGrid cols={2} spacing={50} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
        <div style={{alignSelf: 'center', justifySelf: 'center'}}>
          <Title className={classes.title}>Contact us</Title>
          <Text className={classes.description} mt="sm" mb={30}>
            Send us a description of your project as well as contact details and we will get back to you as soon as we can
          </Text>


          <ContactIconsList variant="white" />

          <Group mt="xl">{icons}</Group>
        </div>
        <form name="contact" className={classes.form} method="post" data-netlify="true" data-netlify-honeypot="bot-field">
        <input type="hidden" name="form-name" value="contact" />
          <TextInput
            label="Email"
            type="email" 
            name="email"
            placeholder="your@email.com"
            required
            classNames={{ input: classes.input, label: classes.inputLabel }}
          />
          <TextInput
            label="Name"
            placeholder="John Doe"
            mt="md"
            type="text" 
            name="name"
            required
            classNames={{ input: classes.input, label: classes.inputLabel }}
          />
          <TextInput
            label="Phone"
            placeholder="John Doe"
            type="text"
            name="phone"
            mt="md"
            classNames={{ input: classes.input, label: classes.inputLabel }}
          />
          <TextInput
            label="Address"
            placeholder="John Doe"
            type="text" 
            name="address"
            mt="md"
            classNames={{ input: classes.input, label: classes.inputLabel }}
          />
          <Textarea
            required
            label="Project description"
            placeholder=""
            minRows={4}
            type="text"
            name="message"
            mt="md"
            classNames={{ input: classes.input, label: classes.inputLabel }}
          />

          <Group position="right" mt="md">
            <Button className={classes.control} type="submit">Send message</Button>
          </Group>
        </form>
      </SimpleGrid>
    </div>

    <section style={{ marginBottom: '-1em'}}>
        <div class="fluid-container snazzy-maps">
            <iframe src="https://snazzymaps.com/embed/51936" width="100%" height="600px" style={{ border: 'none'}} title="map"></iframe>
        </div>
    </section>
    </Layout>
  );
};

export default Contact;
